import { HYDRATE } from 'next-redux-wrapper';
import { createSlice, createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getReq } from 'api';

const hydrate = createAction(HYDRATE);

export const fetchExpertise = createAsyncThunk(
    'expertise/fetchExpertise',
    async (_, { rejectWithValue }) => {
        try {
            const res = await getReq('/expertise');
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

const expertiseSlice = createSlice({
    name: 'expertise',
    initialState: {},
    reducers: {
        // reducer to add newly created expertise to state
        addNewExpertise: (state, { payload }) => {
            if (payload.length > 0) {
                state.data.push(...payload);
            } else {
                state.data.push(payload);
            }
        },
    },
    extraReducers(builder) {
        builder.addCase(hydrate, (state, action) => {
            return {
                ...state,
                ...action.payload.expertise,
            };
        });
        builder.addCase(fetchExpertise.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchExpertise.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.data = payload;
        });
        builder.addCase(fetchExpertise.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        });
    },
});

export const { addNewExpertise } = expertiseSlice.actions;

export default expertiseSlice.reducer;

export const expertiseSelector = ({ expertise }) => expertise;
