import { HYDRATE } from 'next-redux-wrapper';
import {
    createSlice,
    createAction,
    createAsyncThunk,
    isAnyOf,
} from '@reduxjs/toolkit';
import { addNewExpertise } from 'store/expertise';
import { getReq, postReq, deleteReq } from 'api';

const hydrate = createAction(HYDRATE);

// async thunks
export const fetchExpertise = createAsyncThunk(
    'company/fetchExpertise',
    async (id, { getState, rejectWithValue }) => {
        try {
            const res = await getReq(
                `/companies/${getState().company.data.id || id}/expertise`
            );
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const addExpertise = createAsyncThunk(
    'company/addExpertise',
    async (expertiseDto, { dispatch, getState, rejectWithValue }) => {
        try {
            const res = await postReq(
                `/companies/${getState().company.data.id}/expertise`,
                expertiseDto
            );
            if (!expertiseDto.id) {
                dispatch(addNewExpertise(res.data.data));
            }
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const removeExpertise = createAsyncThunk(
    'company/removeExpertise',
    async (id, { getState, rejectWithValue }) => {
        try {
            await deleteReq(
                `/companies/${getState().company.data.id}/expertise/${id}`
            );
            return id;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

const initialState = {
    data: null,
    loading: false,
    error: null,
};
const expertiseSlice = createSlice({
    name: 'company-expertise',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers(builder) {
        builder
            .addCase(hydrate, (state, action) => {
                return {
                    ...state,
                    ...action.payload.company.expertise,
                };
            })
            .addCase(fetchExpertise.fulfilled, (state, { payload }) => {
                state.data = payload;
            })
            .addCase(addExpertise.fulfilled, (state, { payload }) => {
                state.data.push(payload);
            })
            .addCase(removeExpertise.fulfilled, (state, { payload }) => {
                state.data = state.data.filter((item) => item.id !== payload);
            })
            .addMatcher(
                isAnyOf(
                    fetchExpertise.fulfilled,
                    addExpertise.fulfilled,
                    removeExpertise.fulfilled
                ),
                (state) => {
                    state.loading = false;
                    state.error = null;
                }
            )
            .addMatcher(
                isAnyOf(
                    fetchExpertise.pending,
                    addExpertise.pending,
                    removeExpertise.pending
                ),
                (state) => {
                    state.loading = true;
                    state.error = null;
                }
            )
            .addMatcher(
                isAnyOf(
                    fetchExpertise.rejected,
                    addExpertise.rejected,
                    removeExpertise.rejected
                ),
                (state, { payload }) => {
                    state.loading = false;
                    state.error = payload;
                }
            );
    },
});

export const { reset } = expertiseSlice.actions;

export const companyExpertiseSelector = ({ company }) => company.expertise;

export default expertiseSlice.reducer;
