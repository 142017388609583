import {
    createSlice,
    createAction,
    createAsyncThunk,
    isAnyOf,
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { getReq, patchReq, postReq, deleteReq, putReq } from 'api';

const hydrate = createAction(HYDRATE);

export const fetchAlerts = createAsyncThunk(
    'fetchAlerts',
    async (id, { getState, rejectWithValue }) => {
        try {
            let composedRequest = `/companies/${
                getState().company.data.id || id
            }/alerts`;

            if (getState().auth.isAdmin) {
                composedRequest = `/alerts`;
            }

            if (getState().auth.isJournalist) {
                composedRequest = `/companies/${
                    getState().company.data.id || id
                }/alerts-journalists`;
            }

            if (!getState().auth.isAuthenticated) {
                return [];
                // composedRequest = `/alerts`;
            }

            const res = await getReq(composedRequest);

            return res.data.data.items
                .sort((a, b) => new Date(a.deadline) - new Date(b.deadline))
                .slice(-100);
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchAlertBySlug = createAsyncThunk(
    'fetchAlertBySlug',
    async ({ slug, author }, { rejectWithValue }) => {
        try {
            const res = await getReq(
                `/alerts/slug/${slug}${author === '0' ? '?author=0' : ''}`
            );
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchResponses = createAsyncThunk(
    'fetchResponses',
    async (id, { rejectWithValue }) => {
        try {
            const res = await getReq(`/alerts/${id}/responses?size=10000`);
            return res.data.data.items;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const addAlert = createAsyncThunk(
    'addAlert',
    async (alertDto, { rejectWithValue }) => {
        try {
            const res = await postReq(`/alerts`, alertDto);
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateAlert = createAsyncThunk(
    'updateAlert',
    async (alertDto, { rejectWithValue }) => {
        try {
            const res = await putReq(`/alerts/${alertDto.id}`, alertDto);
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const closeAlert = createAsyncThunk(
    'closeAlert',
    async (id, { rejectWithValue }) => {
        try {
            const res = await postReq(`/alerts/${id}/close-alert`);
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const removeAlert = createAsyncThunk(
    'removeAlert',
    async (id, { rejectWithValue }) => {
        try {
            await deleteReq(`/alerts/${id}`);
            return id;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const addAlertResponse = createAsyncThunk(
    'addAlertResponse',
    async ({ id, ...responseDto }, { dispatch, getState, rejectWithValue }) => {
        try {
            const res = await postReq(
                `companies/${
                    getState().company.data.id
                }/alerts/${id}/responses`,
                responseDto
            );
            dispatch(addResponse(id));
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const removeAlertResponse = createAsyncThunk(
    'removeAlertResponse',
    async ({ alertId, responseId }, { rejectWithValue }) => {
        try {
            await deleteReq(`/alerts/${alertId}/responses/${responseId}`);
            return responseId;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateAlertResponse = createAsyncThunk(
    'updateAlertResponse',
    async (
        { alertId, responseId, ...responseDto },
        { getState, rejectWithValue }
    ) => {
        const reqUrl = getState().auth.isAdmin
            ? `/alerts/${alertId}/responses/${responseId}`
            : `/companies/${
                  getState().company.data.id
              }/alerts/${alertId}/responses/${responseId}`;
        try {
            const res = await putReq(reqUrl, responseDto);
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const orderAlertResponse = createAsyncThunk(
    'orderAlertResponse',
    async ({ alertId, responseId, index }, { rejectWithValue }) => {
        try {
            await putReq(`/alerts/${alertId}/responses/${responseId}/order`, {
                index,
            });
            return { id: responseId, order: index };
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const publishAlert = createAsyncThunk(
    'publishAlert',
    async (id, { rejectWithValue }) => {
        try {
            const res = await postReq(`/alerts/${id}/publish`);
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);
export const unpublishAlert = createAsyncThunk(
    'unpublishAlert',
    async (id, { rejectWithValue }) => {
        try {
            const res = await postReq(`/alerts/${id}/unpublish`);
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

const initialState = {
    data: [],
};

const alertsSlice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        addResponse: (state, { payload }) => {
            state.data = state.data.map((item) => {
                if (item.id === payload) {
                    item.responsesLeft -= 1;
                    if (item.responsesLeft === 0) {
                        item.status = 'inactive';
                        item.deadline = new Date().toISOString();
                    }
                    return item;
                }
                return item;
            });
            if (state.alert) {
                state.alert.responsesLeft -= 1;
                if (state.alert.responsesLeft === 0) {
                    state.alert.status = 'inactive';
                    state.alert.deadline = new Date().toISOString();
                }
            }
        },

        findResponse: (state, { payload }) => {
            state.alert = state.data.find((item) => item.id === payload);
        },
        reset: () => initialState,
    },
    // reducer to persist
    extraReducers(builder) {
        builder.addCase(hydrate, (state, action) => {
            return {
                ...state,
                ...action.payload.alerts,
            };
        });

        builder.addCase(fetchAlerts.fulfilled, (state, { payload }) => {
            state.data = payload;
            state.alert = null;
        });
        builder.addCase(fetchAlertBySlug.fulfilled, (state, { payload }) => {
            state.alert = payload;
        });
        builder.addCase(fetchResponses.fulfilled, (state, { payload }) => {
            state.alertResponses = payload;
        });
        builder.addCase(addAlert.fulfilled, (state, { payload }) => {
            state.alert = payload;
            state.data = [payload, ...state.data];
        });
        builder.addCase(updateAlert.fulfilled, (state, { payload }) => {
            state.alert = { ...state.alert, ...payload };
        });
        builder.addCase(closeAlert.fulfilled, (state, { payload }) => {
            state.alert = payload;
        });
        builder.addCase(addAlertResponse.fulfilled, (state, { payload }) => {
            state.alertResponses.push(payload);
        });
        builder.addCase(removeAlertResponse.fulfilled, (state, { payload }) => {
            state.alertResponses = state.alertResponses.filter(
                (item) => item.id !== payload
            );
        });
        builder.addCase(updateAlertResponse.fulfilled, (state, { payload }) => {
            state.alertResponses = state.alertResponses.map((item) => {
                if (item.id === payload.id) {
                    return payload;
                }
                return item;
            });
        });
        builder.addCase(removeAlert.fulfilled, (state, { payload }) => {
            state.alert = null;
        });
        builder.addCase(
            orderAlertResponse.fulfilled,
            (state, { payload: { id, order } }) => {
                const index = state.alertResponses.findIndex(
                    (item) => item.id === id
                );
                const [item] = state.alertResponses.splice(index, 1);

                if (order < 0) {
                    state.alertResponses.push({ ...item, index: null });
                } else {
                    state.alertResponses.splice(0, 0, { ...item, index: 0 });
                }
            }
        );
        builder.addCase(publishAlert.fulfilled, (state, { payload }) => {
            state.data = state.data.map((item) => {
                if (item.id === payload.id) {
                    return { ...item, ...payload };
                }
                return item;
            });
        });
        builder.addCase(unpublishAlert.fulfilled, (state, { payload }) => {
            state.data = state.data.map((item) => {
                if (item.id === payload.id) {
                    return { ...item, ...payload };
                }
                return item;
            });
        });

        builder.addMatcher(
            isAnyOf(
                fetchAlerts.fulfilled,
                removeAlert.fulfilled,
                fetchAlertBySlug.fulfilled,
                fetchResponses.fulfilled,
                addAlert.fulfilled,
                closeAlert.fulfilled,
                addAlertResponse.fulfilled,
                removeAlertResponse.fulfilled,
                updateAlertResponse.fulfilled,
                orderAlertResponse.fulfilled,
                publishAlert.fulfilled,
                unpublishAlert.fulfilled,
                updateAlert.fulfilled
            ),
            (state) => {
                state.loading = false;
                state.error = null;
            }
        );
        builder.addMatcher(
            isAnyOf(
                fetchAlerts.pending,
                removeAlert.pending,
                fetchAlertBySlug.pending,
                fetchResponses.pending,
                addAlert.pending,
                closeAlert.pending,
                addAlertResponse.pending,
                removeAlertResponse.pending,
                updateAlertResponse.pending,
                orderAlertResponse.pending,
                publishAlert.pending,
                unpublishAlert.pending,
                updateAlert.pending
            ),
            (state) => {
                state.loading = true;
                state.error = null;
            }
        );
        builder.addMatcher(
            isAnyOf(
                fetchAlerts.rejected,
                removeAlert.rejected,
                fetchAlertBySlug.rejected,
                fetchResponses.rejected,
                addAlert.rejected,
                closeAlert.rejected,
                addAlertResponse.rejected,
                removeAlertResponse.rejected,
                updateAlertResponse.rejected,
                orderAlertResponse.rejected,
                publishAlert.rejected,
                unpublishAlert.rejected,
                updateAlert.rejected
            ),
            (state, { payload }) => {
                state.alert = null;
                state.loading = false;
                state.error = payload;
            }
        );
    },
});

export const { addResponse, reset } = alertsSlice.actions;

export const alertSelector = ({ alerts }) => alerts;

export default alertsSlice.reducer;
