import { HYDRATE } from 'next-redux-wrapper';
import {
    createSlice,
    createAction,
    createAsyncThunk,
    isAnyOf,
} from '@reduxjs/toolkit';
import { postReq, deleteReq, patchReq, getReq } from 'api';

const hydrate = createAction(HYDRATE);

export const fetchCoverages = createAsyncThunk(
    'company/fetchCoverages',
    async (id, { getState, rejectWithValue }) => {
        try {
            const res = await getReq(
                `/companies/${getState().company.data.id || id}/coverages`
            );
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const addCoverage = createAsyncThunk(
    'company/addCoverage',
    async (coverageDto, { getState, rejectWithValue }) => {
        try {
            const res = await postReq(
                `/companies/${getState().company.data.id}/coverages`,
                coverageDto
            );
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const removeCoverage = createAsyncThunk(
    'company/removeCoverage',
    async (id, { getState, rejectWithValue }) => {
        try {
            await deleteReq(
                `/companies/${getState().company.data.id}/coverages/${id}`
            );
            return id;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const checkCoverage = createAsyncThunk(
    'company/checkCoverage',
    async (url, { rejectWithValue }) => {
        try {
            const res = await postReq(`/coverages/fetch-coverage`, { url });
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const pinCoverage = createAsyncThunk(
    'company/pinCoverage',
    async (id, { getState, rejectWithValue }) => {
        try {
            const res = await patchReq(
                `/companies/${getState().company.data.id}/coverages/${id}/pin`
            );
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const seeCoverages = createAsyncThunk(
    'company/seeCoverages',
    async (id, { getState, rejectWithValue }) => {
        try {
            const res = await patchReq(
                `/companies/${getState().company.data.id}/coverages/see`
            );
            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

const initialState = {
    data: null,
    loading: false,
    error: null,
    coverage: null,
};

const coveragesSlice = createSlice({
    name: 'coverages',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers(builder) {
        builder
            .addCase(hydrate, (state, action) => {
                return {
                    ...state,
                    ...action.payload.company.coverages,
                };
            })
            .addCase(fetchCoverages.fulfilled, (state, { payload }) => {
                state.data = payload;
                state.coverage = null;
            })
            .addCase(addCoverage.fulfilled, (state, { payload }) => {
                state.data = [payload, ...state.data];
            })
            .addCase(removeCoverage.fulfilled, (state, { payload }) => {
                state.data = state.data.filter((item) => item.id !== payload);
            })
            .addCase(checkCoverage.fulfilled, (state, { payload }) => {
                state.coverage = payload;
            })
            .addCase(pinCoverage.fulfilled, (state, { payload }) => {
                state.data = state.data.map((item) => {
                    if (item.id === payload.id) {
                        return payload;
                    }
                    return item;
                });
            })
            .addMatcher(
                isAnyOf(
                    fetchCoverages.fulfilled,
                    addCoverage.fulfilled,
                    removeCoverage.fulfilled,
                    checkCoverage.fulfilled
                ),
                (state) => {
                    state.loading = false;
                    state.error = null;
                }
            )
            .addMatcher(
                isAnyOf(
                    fetchCoverages.pending,
                    addCoverage.pending,
                    removeCoverage.pending,
                    checkCoverage.pending
                ),
                (state) => {
                    state.loading = true;
                    state.error = null;
                }
            )
            .addMatcher(
                isAnyOf(
                    fetchCoverages.rejected,
                    addCoverage.rejected,
                    removeCoverage.rejected,
                    checkCoverage.rejected
                ),
                (state, { payload }) => {
                    state.loading = false;
                    state.coverage = null;
                    state.error = payload;
                }
            );
    },
});

export const { reset } = coveragesSlice.actions;

export const coveragesSelector = ({ company }) => company.coverages;

export default coveragesSlice.reducer;
